import React from 'react';

import Layout from "../../components/layout";
import { Link } from 'gatsby';
import { Title,Testonimal } from "../../components/Globals";
import {Helmet} from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import  "./awesome-slider-override.css";
// import 'react-awesome-slider/src/styles.js';

const SalesforceConsultingPage = () => {

  const [services] = React.useState([
    {
      id: 1,
      title: 'Marketing Automation Experience',
      desc: '10+ years of experience with successful installation across multiple segments.',
      image: 'marketing-automation-services-india-usa.png',
      alt:'Salesforce marketing automation services USA India',
      imagetitle:'Experience ',
      url: {
       
      }
    },
    {
      id: 2,
      title: 'Certifications',
      desc: 'Team with 115+ Certified Salesforce professionals. ',
      image: 'salesforce-marketing-automation-certified-consultants.png',
      alt:'marketing automation Salesforce certified consultants India USA',
      imagetitle:'Salesforce Certifications',
      url: {
      }
    },
    {
      id: 3,
      title: 'End-to-End Support',
      desc: 'One stop shop for the entire spectrum of marketing automation, including implementation, strategy, content, campaigns and analytics. ',
      image: 'marketing-automation-agencies-india-usa.png',
      alt:'marketing automation agencies India',
      imagetitle:'Salesforce Support',
      url: {
      }
    },
    {
      id: 4,
      title: 'Industry Expertise',
      desc: 'We understand industry specific challenges and infuse strategic guidance & best practices in our solutions. ',
      image: 'marketing-automation-companies.png',
      alt:'marketing-automation-companies',
      imagetitle:'Industry Experience',
      url: {
      }
    },
 
  ]);

  const testimonials = [
    {
      customerName: "David Hanson",
      customerProfile: "CEO, Koalify",
      imageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
      quote:
        "We have been using servana for about 2 years. And in that time we have had no problem at all. The user interface is really simple to use. Our services scale automatically and we never have to worry about downtimes. is as described."
    },
    {
      customerName: "Serena Davis",
      customerProfile: "Founder, Travana",
      imageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
      quote:
        "We are delighted with the quality and performance of the servers that servana provides. The uptime is amazing and the internet connection is great for the price we are paying."
    },
    {
      customerName: "Timothy Burr",
      customerProfile: "CTO, Coronax",
      imageSrc:
        "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=256&h=256&q=80",
      quote:
        "It has been 8 months since we have switched to servana and it has nothing but an amazing experience. The cost is affordable, support is great, uptime is as described."
    }
  ];

  return (
    <Layout>
       
        <div className="salesforce-consulting h-screen">
        <Helmet>
        <title>Marketing Automation Services | Salesforce Partner | India, USA, UK, Poland</title>
        <meta name="description" content="Cymetrix is one of the top marketing automation services company, providing end-to-end support of automation platform implementation services, creative, campaign management, etc."/>
        <meta name="keywords" content="cymetrix,marketing automation services, marketing automation, marketing automation agencies, marketing automation companies, marketing automation service providers, marketing automation india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/marketing-automation-services/" />
      </Helmet>
            <h1 className="text-base xl:text-4xl xl:m-64 md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
            text-white text-uppercase text-center">
             CREATE MARKETING MAGIC WITH CYMETRIX'S MARKETING AUTOMATION SERVICES				
            </h1>
        </div>
        
        <div className="max-w-6xl mx-auto justify-center my-3 md:my-12 ">
          <h1 className="text-3xl my-3 sma:mx-3">Salesforce Marketing Automation Services</h1>
          <p className="text-lg sma:mx-3">Designing an automated digital journey for your customer base is not exactly an easy task. To top it off, the array of marketing tools continues to expand annually, making it challenging to stay ahead of the technological curve.  </p>

          <p className="text-lg my-3 sma:mx-3">So, how can you ensure not just keeping pace with these advancements but also maximizing the ROI on your marketing investments?  </p>
          <p className="text-lg my-3 sma:mx-3">The solution lies in a blend of collaborative teamwork and technical prowess. Our team comprises of industry veterans who comprehend the intricacies of your sector, the technological landscape, and the nuances of effective marketing strategies. We’ll partner with you to formulate a roadmap and value proposition geared towards rapid time-to-value, while concurrently nurturing the growth of your team. </p>
     
      </div>

        <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Our Expertise Across Marketing Cloud Suite</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Engagement</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Drive meaningful engagement with your audience through an integrated approach spanning email, mobile, web, and advertising channels. Craft seamless journeys across the entire customer lifecycle, empowered by robust analytics to continually refine and enhance your campaigns.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Personalization</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Get a comprehensive view of customers across multiple touchpoints. Then leverage customer data to automate and personalize customer journeys across multiple channels in real-time. </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Intelligence</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Revolutionize your marketing efforts with our Marketing Intelligence solutions powered by Datorama. Drive customer engagement, boost conversions, and execute targeted marketing campaigns with precision and efficiency.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Data Cloud for Marketing</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Unify your customer Data with Data Cloud for Marketing. Transform your business with real-time insights, 360° view of customer data and deliver hyper-personalized experience at scale.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Marketing Cloud Account Engagement (Pardot)</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Experience a comprehensive solution for all your marketing automation needs. Reimagine your business by streamlining your sales pipeline and driving overall sales growth through our innovative solutions powered by Pardot. </div>
                            </div>
                        </div>


                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

          <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              CYMETRIX SALESFORCE EXPERTISE 
 														
              </h1><br />
              <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/top-salesforce-implementation-partners-usa-india-uk.png')}
                      alt="digital marketing automation services agency in India, USA, Poland, UK"
                      title="Cymterix Salesforce Expertise "
                      />
              </div>
              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">               
             
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
             SALESFORCE CERTIFIED TEAM 
 														
              </h1><br /> 
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-certified-consultants-usa-india.png')}
                      alt="marketing automation consultants b2b"
                      title="Skilled team of Salesforce certified consultants"
                      />
                
              </div>
          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Choose Cymetrix for Marketing Automation Services" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24"/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>


        <div className="bg-gray-300 w-full flex flex-col items-center ">
            <div className="max-w-5xl mx-4 sm:mx-2 sma:mx-2">
                <h1 className="text-3xl w-full text-gray-800 text-uppercase text-center mt-20 mb-5 md:text-3xl sma:text-xl">
                A TRUSTED SALESFORCE PARTNER IN USA & INDIA
                </h1>
                <div className="flex flex-col md:flex-row items-center content-between my-10">
                  <img src={require('../../images/home/salesforce-consulting-partner.png')} className="w-48" alt="top Salesforce consulting partner company"/>
                  <p className="mx-5 sma:my-10 text-gray-700 text-justify">Cymetrix is one of the leading Salesforce implementation partners in USA with experience and expertise to drive Salesforce-powered digital transformation for your enterprise. Our offshore Salesforce development team can help clients leverage the Salesforce platform effectively to innovate and create value from customer operations.</p>
                </div>
            </div>
        </div>


        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Our Marketing Automation Services
          </span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">SALESFORCE MARKETING CLOUD IMPLEMENTATIONS</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Our SFMC developers and marketing automation specialists collaborate closely with your IT department to seamlessly deploy, integrate, and migrate the solution into your existing IT infrastructure. By using our custom solutions, we ensure a flawless, thoroughly tested solution delivered on schedule.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">INTEGRATION OF MARKETING AUTOMATION</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Marketing automation integration involves the connection of a marketing automation platform with another tool through their application programming interfaces (APIs). This enables real-time or near-real-time data sharing between platforms, ensuring synchronization for optimal efficiency.
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">STRATEGIC CAMPAIGN DEVELOPMENT & MANAGEMENT</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">A successful marketing automation campaign demands meticulous planning, timely execution, and a profound understanding of your target audience. From initial planning to execution, tracking, optimization, and comprehensive analysis, our consulting and campaign management services cover the entire spectrum.
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">CREATIVE & MEDIA SERVICES</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Our Media and Creative teams collaborate seamlessly to drive successful campaigns. The creative team crafts engaging communications, while the media team maximizes the reach of the marketing automation campaign by leveraging multiple marketing channels.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">MARKETING ANALYTICS</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Harness the power of data to drive marketing success for your business. Our comprehensive Marketing Analytics services will help you gain actionable insights into campaign performance, audience engagement, and conversion rates, empowering you to refine your strategies and achieve measurable marketing results.
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">TRAINING & SUPPORT</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">We offer training sessions and ongoing support to ensure your clients' teams are proficient in using the marketing automation tools effectively. This can include training on platform features, best practices, and troubleshooting assistance.
                  
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Case Studies</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partner-india-fintech.png')} alt="Salesforce consulting fintech company | Cymetrix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/">Revolutionizing bill discounting with a self-serving co-lending fintech platform</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>




        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/sfmc-vs-pardot-compare.png')} alt="Salesforce Marketing Cloud vs Pardot| Cymterix" title="B2B vs B2C marketing automation solution comparision" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2024/01/24/salesforce-marketing-cloud-vs-pardot-which-one-to-choose/">Salesforce Marketing Cloud vs Pardot</Link></h1>
                            <p className="text-lg">Interpret and evaluate the best fit of Salesforce Marketing Automation for your organization.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Marketing Cloud For Personalization" title="Salesforce Marketing Cloud For Personalization" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/08/22/salesforce-marketing-cloud-for-personalization/">Salesforce Marketing Cloud for Personalization</Link></h1>
                            <p className="text-lg">Learn how to leverage data-driven insights to deliver personalized experiences and drive sales.</p>
                        </div>
                    </div>
                </div>
            </div>

        <Testonimal />
        


        <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg" style={{color: '#1e407b'}}><b>FAQs:</b></h4>
                        <br/>
                        <h4 class="text-lg"><b>Why choose Cymetrix as your Salesforce Marketing Automation Agency? </b></h4>
                        <p> Cymetrix brings unparalleled expertise in Salesforce Marketing Automation, offering tailored solutions to amplify your marketing efforts. Our team is dedicated to maximizing your marketing ROI through personalized and custom marketing solutions.</p>
                        <br/>
                        <h4 class="text-lg"><b>Which businesses can benefit from Cymetrix’s Marketing Automation services? </b></h4>
                        <p> Cymetrix's Marketing Automation services cater to a diverse range of businesses across industries, including e-commerce, healthcare, finance, manufacturing, and more. Whether you're a startup or an enterprise, our solutions are designed to streamline your marketing processes and drive tangible results.</p>
                        <br/>
                        <h4 class="text-lg"><b>In which location does Cymetrix offer Salesforce Marketing Automation Services?</b></h4>
                        <p>We provide near-shore consultation services and offshore delivery powered by our network of offices in USA, UK, Europe, Japan and India.</p>
                        <br/>
                        <h4 class="text-lg"><b>What kind of support is available for Pardot and Salesforce integration? </b></h4>
                        <p>Cymetrix offers comprehensive support throughout the entire process of Pardot integration with Salesforce, including pre-integration planning, setup, and post-integration optimization. Our team also offers nd to end support to help the marketing and sales teams get up to speed with the integrated system and run strategic and creative campaigns. Additionally, ongoing support is available to address any issues, perform updates, and ensure that your Pardot and Salesforce integration continues to meet your business needs.</p>
                        <br/>
                        <h4 class="text-lg"><b>What tools are available for email automation in Salesforce?</b></h4>
                        <p>Salesforce offers several tools for email automation, each catering to different needs:</p>
                        <p>Salesforce Marketing Cloud: A powerful platform for managing large-scale B2C email campaigns, including journey builder for personalized customer journeys, email studio for targeted email creation, and automation studio for complex workflows.</p>
                        <p>Pardot: Ideal for B2B marketing, Pardot offers email automation features such as lead nurturing campaigns, autoresponders, and drip campaigns, all integrated with Salesforce CRM for seamless data sharing.  </p>
                        <p>Salesforce Engage: A tool within Pardot that enables sales teams to send one-to-one emails at scale, with tracking and automation features to help them engage prospects more effectively</p>
                        <p>Process Builder and Flow: Native Salesforce automation tools that can be used to trigger email alerts based on specific actions or criteria within Salesforce.</p>
                    </div>
                </div>


      </Layout>
    );
}

export default SalesforceConsultingPage;
